import React from "react";

const HeroBanner = () => {
  return (
    <div className="bg-orange-400 px-8 py-2.5 sm:px-3.5">
      <div className="mt-24 mb-4 flex items-center justify-center">
        <p className="text-base text-white leading-6 text-center">
          Neukunden-Bonus: Erhalten Sie eine höhere Prämie von 15 € auf das
          erste E-Fahrzeug der Klasse M1 ODER N1
        </p>
      </div>
    </div>
  );
};

export default HeroBanner;
